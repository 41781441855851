import React from 'react'

import { IProvider } from '@/components/admin/auth/types'

import { AuthDivider } from './AuthDivider'
import { MagicLinkForm } from './MagicLinkForm'
import { OAuthProviders } from './OAuthProviders'

interface Props {
  providers: IProvider[]
  callbackUrl: string
}

export const RegisterForm: React.FC<Props> = ({ providers, callbackUrl }) => {
  return (
    <div className="mx-auto mt-8 w-full max-w-2xl">
      <div className="bg-white px-4 pb-8 pt-2 shadow sm:rounded-lg sm:px-10">
        <AuthDivider>Use an existing provider to register</AuthDivider>
        <OAuthProviders type="Register" providers={providers} callbackUrl={callbackUrl} />
        <AuthDivider>or create an account via email</AuthDivider>
        <MagicLinkForm type="Register" callbackUrl={callbackUrl} />
      </div>
    </div>
  )
}
