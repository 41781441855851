import { signIn } from 'next-auth/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { IProvider } from '@/components/admin/auth/types'
import { SocialLogos } from '@/components/admin/common/SocialLogos'

interface Props {
  type: string
  providers: IProvider[] | undefined
  callbackUrl: string
}

export const OAuthProviders: React.FC<Props> = ({ type = 'register', providers, callbackUrl }) => {
  return (
    <div
      className={twMerge(
        'mb-6 grid gap-3',
        type === 'Register'
          ? 'grid-cols-1 whitespace-nowrap text-lg'
          : 'grid-cols-1 text-sm sm:grid-cols-1'
      )}
    >
      {providers?.map((provider, index) => (
        <React.Fragment key={index}>
          {provider.id !== 'email' && provider.id !== 'credentials' && (
            <div className="min-w-min">
              <a
                onClick={() => signIn(provider.id, { callbackUrl })}
                className="inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 shadow-sm hover:bg-gray-50"
              >
                <span className="sr-only">Sign in with {provider.name}</span>
                <SocialLogos brand={provider.name} />
              </a>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  )
}
