import { signIn } from 'next-auth/react'
import React from 'react'
import * as yup from 'yup'

import { FormControl } from '@/components/shared/form/FormControl'
import { yupValidator } from '@/components/shared/form/yupValidator'

const schema = yup.object().shape({
  email: yup.string().required('Email is required.').email('Not a valid email')
})

export const useMagicLinkForm = (callbackUrl: string) => {
  const form = React.useRef(
    new FormControl({
      validator: yupValidator(schema),
      validationMode: 'onSubmit',
      onSubmit: async control => {
        const data = control.getValues()
        await signIn('email', {
          email: data.email,
          callbackUrl
        })
      }
    })
  )
  return form.current
}
