import { useRouter } from 'next/router'

import { useDispatch } from '@/lib/client/redux/hooks'
import { LocationAction } from '@/lib/client/redux/location/LocationAction'

export const useAuthCallbackUrl = () => {
  const router = useRouter()
  const dispatch = useDispatch()

  const callbackUrl = (router.query.callbackUrl as string) || '/admin/team'
  dispatch(
    LocationAction.modifyQuery((_url, as) => {
      as.delete('callbackUrl')
    })
  )
  return callbackUrl
}
