import React from 'react'

import { TextInput } from '@/components/admin/common/form/controlled/TextInput'
import { Button } from '@/components/shared/button/Button'
import { useFormSubmitStatus } from '@/components/shared/form/useFormSubmitStatus'
import { useMagicLinkForm } from '@/lib/client/redux/user/hooks/useMagicLinkForm'
interface Props {
  type: string
  callbackUrl: string
}

export const MagicLinkForm: React.FC<Props> = ({ type = 'Register', callbackUrl }) => {
  const form = useMagicLinkForm(callbackUrl)
  const status = useFormSubmitStatus(form)
  return (
    <form className="space-y-6">
      <TextInput
        label="Email Address"
        form={form}
        size="lg"
        id="email"
        placeholder="Email"
        autoComplete="current-email"
      />
      <Button
        loading={status === 'submitting'}
        loadingText="Creating link..."
        onClick={form.submit}
      >
        {type === 'Register' ? ' Get ' : ' Sign in with '} a magic link via Email
      </Button>
      <div className="my-4 w-full rounded-lg p-4 text-center text-gray-400">
        We’ll email you a magic code for a password-optional{' '}
        {type === 'Register' ? ' registration' : ' sign in'}.
      </div>
    </form>
  )
}
