import { AuthContainer } from '@/components/admin/auth/AuthContainer'
import { AuthError } from '@/components/admin/auth/AuthError'
import { AuthHeader } from '@/components/admin/auth/AuthHeader'
import { RegisterForm } from '@/components/admin/auth/RegisterForm'
import { useAuthCallbackUrl } from '@/lib/client/common/util/getAuthCallbackURL'
import { IAuthProps, getAuthProps } from '@/lib/server/util/getAuthProps'

function Register(props: IAuthProps) {
  const { providers } = props
  const callbackUrl = useAuthCallbackUrl()
  return (
    <AuthContainer>
      <AuthError />
      <AuthHeader type="register" callbackUrl={callbackUrl} />
      <RegisterForm providers={providers} callbackUrl={callbackUrl} />
    </AuthContainer>
  )
}

export default Register

export const getServerSideProps = getAuthProps
