import memoizeOne from 'memoize-one'

export const Query = {
  get: () => getParams(),
  string: (name: string) => {
    const value = getParams().get(name)
    return value ?? undefined
  },
  int: (name: string) => {
    const value = getParams().get(name)
    if (value === null) {
      return undefined
    }
    const parsed = parseInt(value)
    return !isNaN(parsed) ? parsed : undefined
  }
}

const getParams = () => {
  return parseParams(window.location.search)
}

const parseParams = memoizeOne((path: string) => {
  return new URLSearchParams(path)
})
