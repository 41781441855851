
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/admin/auth/register",
      function () {
        return require("private-next-pages/admin/auth/register.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/admin/auth/register"])
      });
    }
  