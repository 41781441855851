import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import { useDispatch } from '@/lib/client/redux/hooks'
import { LocationAction } from '@/lib/client/redux/location/LocationAction'
import { config } from '@/lib/common/config'
import { authUrl } from '@/lib/common/utils/urls'

interface Props {
  type?: string
  callbackUrl: string
}

export const AuthHeader: React.FC<Props> = ({ type = 'register', callbackUrl }) => {
  const dispatch = useDispatch()

  return (
    // Form header
    <div className="sm:mx-auto sm:w-full sm:max-w-sm md:max-w-md">
      <div className="flex justify-center">
        <Link className="flex items-center cursor-pointer" href={config.env.MARKETING_URL}>
          <Image
            src="/images/logo/logo.svg"
            className="w-32 h-12"
            width={128}
            height={48}
            alt="Logo"
            priority
          />
        </Link>
      </div>

      <h2 className="mt-6 text-2xl font-extrabold text-center text-gray-900">
        {type === 'register' && <>Create an account</>}
        {type === 'sign in' && <>Have an account? Sign in</>}
      </h2>
      <div className="mt-2 text-center">
        {type === 'register' && (
          <>
            <span className="mr-1">Existing user? </span>
            <a
              className="underline cursor-pointer over:no-underline text-primary"
              onClick={() =>
                dispatch(LocationAction.push({ path: authUrl('/signin'), query: { callbackUrl } }))
              }
            >
              Sign in
            </a>
          </>
        )}
        {type === 'sign in' && (
          <>
            <span className="mr-1">New here? </span>
            <a
              className="underline cursor-pointer over:no-underline text-primary"
              onClick={() =>
                dispatch(
                  LocationAction.push({ path: authUrl('/register'), query: { callbackUrl } })
                )
              }
            >
              Create an account
            </a>
          </>
        )}
      </div>
    </div>
  )
}
