import React from 'react'

import { Container } from '@/components/shared/layout/Container'

export const AuthContainer: React.FC = ({ children }) => {
  return (
    <Container className="min-w-sm">
      <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
        {children}
      </div>
    </Container>
  )
}
