import React from 'react'

import { Query } from '@/lib/client/common/navigation/Query'
import { config } from '@/lib/common/config'

export const AuthError: React.FC = () => {
  const error = Query.string('error')
  if (!error) {
    return null
  }
  return (
    <div className="max-w-2xl px-4 py-2 mx-auto mb-10 text-lg font-bold text-red-400 bg-red-100 border-red-400 rounded-md">
      {parseError(error)}
    </div>
  )
}

function parseError(type: string) {
  const contact_support = `If this problem persists, please contact ${config.env.EMAIL_SUPPORT} from that email so we can help you.`

  switch (type) {
    case 'NotAllowedEnvironment':
      return <>You don&apos;t have access to this environment: {config.env.ADMIN_URL}.</>
    case 'EmailSignin':
      return (
        <>
          Sorry! There was a problem sending to this email. Please doublecheck it for errors and try
          sending it again.
          <br />
          <br />
          {contact_support}
        </>
      )
    case 'Verification':
      return (
        <>
          Sorry, the link is not valid any more.
          <br />
          <br />
          {contact_support}
        </>
      )
    default:
      return `Sorry, something went wrong. Please try again. ${contact_support}`
  }
}
